import { inject, Injectable, InjectionToken } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { QueryEntityCustom } from '@memberspot/frontend/shared/data-access/common';
import {
  Customer,
  hasOpenInvoices,
  Plan,
  SubscriptionStatus,
} from '@memberspot/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomersStore, CustomerState } from './customers.store';

export const CUSTOMER = new InjectionToken<Observable<Customer | undefined>>(
  'current customer',
  {
    providedIn: 'root',
    factory: () => inject(CustomersQuery).selectActive(),
  },
);

@Injectable({ providedIn: 'root' })
export class CustomersQuery extends QueryEntityCustom<CustomerState> {
  constructor(store: CustomersStore, routerQuery: RouterQuery) {
    super(store, routerQuery);
  }

  selectNoPlan() {
    return this.selectActive().pipe(map((c) => this._hasNoPlan(c)));
  }

  selectFailedPayment() {
    return this.selectActive().pipe(
      map((c) => {
        if (
          c?.status === SubscriptionStatus.PAST_DUE ||
          c?.status === SubscriptionStatus.UNPAID ||
          c?.status === SubscriptionStatus.CANCELED ||
          c?.status === SubscriptionStatus.INCOMPLETE_EXPIRED ||
          c?.requiresAction ||
          (c && hasOpenInvoices(c))
        ) {
          return true;
        }

        return false;
      }),
    );
  }

  private _hasNoPlan(customer?: Customer) {
    return (
      customer?.plan === Plan.TRIAL ||
      customer?.status === SubscriptionStatus.CANCELED ||
      customer?.status === SubscriptionStatus.INCOMPLETE_EXPIRED
    );
  }
}
