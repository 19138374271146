import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { Customer, CustomerStorage } from '@memberspot/models';

import { setTrialing } from '../subscription.helper';
export interface CustomerState
  extends EntityState<Customer, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customers' })
export class CustomersStore extends EntityStore<CustomerState> {
  constructor() {
    super();
    this.akitaPreAddEntity = this.akitaPreAddEntity.bind(this);
    this.akitaPreUpdateEntity = this.akitaPreUpdateEntity.bind(this);
  }

  override akitaPreAddEntity(newEntity: Customer) {
    const customer = setTrialing(newEntity);

    return this.calcStorage(customer);
  }

  override akitaPreUpdateEntity(prevEntity: Customer, nextEntity: Customer) {
    const customer = setTrialing(nextEntity);

    return this.calcStorage(customer);
  }

  calcStorage(customer: Customer): Customer {
    const storage = customer?.storage
      ? this.getStorageSum(customer.storage)
      : 0;

    customer.storageSum = storage;

    return customer;
  }

  getStorageSum(storage: CustomerStorage) {
    return Object.values(storage).reduce((prev, curr) => prev + curr, 0);
  }
}
