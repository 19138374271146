import { NotiService } from '@memberspot/frontend/shared/util/noti';
import { Customer, SubscriptionStatus } from '@memberspot/models';
import { Plan } from '@memberspot/models';
import { DateHelpers } from '@memberspot/shared/util/helper';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

const isInTrial = (customer: Customer) => customer?.plan === Plan.TRIAL;

export const setTrialing = (customer: Customer): Customer => {
  const newEntity = { ...customer };

  if (isInTrial(newEntity) && newEntity?.startDate?.toDate) {
    newEntity.trailing = true;
    newEntity.remainingTrailDays =
      14 + DateHelpers.dateDiffToTodayInDays(newEntity?.startDate?.toDate());
    newEntity.locked = newEntity.remainingTrailDays >= 0 ? false : true;
  } else if (
    newEntity.status === SubscriptionStatus.CANCELED ||
    newEntity.status === SubscriptionStatus.UNPAID ||
    newEntity.status === SubscriptionStatus.INCOMPLETE_EXPIRED
  ) {
    newEntity.trailing = false;
    newEntity.locked = true;
  } else {
    newEntity.trailing = false;
    newEntity.locked = false;
  }

  return newEntity;
};

export const checkCustomer = (
  newCustomer: Customer,
  noti: NotiService,
  gtmService: GoogleTagManagerService | null,
  showMes?: boolean,
): true | 'trialExpired' | 'accountLocked' => {
  const customer = setTrialing(newCustomer);

  if (customer?.trailing && customer?.locked) {
    if (showMes) {
      noti.warningIntl('admin.notifications.customers.trialExpired');
    } else if (gtmService) {
      gtmService.pushTag({
        event: 'trialExpired',
      });
    }

    return 'trialExpired';
  } else if (customer?.locked) {
    if (showMes) {
      noti.infoIntl('admin.notifications.customers.accountLocked');
    }

    return 'accountLocked';
  }

  return true;
};
